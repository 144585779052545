<template>
  <div class="Brands">
    <img src="../assets/image/021.png" />
    <div class="tuji">
      <img src="../assets/image/031.png" />
      <img src="../assets/image/032.png" />
      <img src="../assets/image/033.jpg" />
    </div>
    <img src="../assets/image/034.png" class="tu01" />
  </div>
</template>

<style scoped lang="less">
.Brands {
  width: 1200px;
  height: 550px;
  background-color: rgb(255, 255, 255);
  margin-top: 130px;
  .tuji {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .tu01 {
    margin-top: 60px;
  }
}
</style>
